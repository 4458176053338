import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { Item } from "../../pojo/Item";
import { Proto } from "../../pojo/Proto";
import { S25QLConst } from "./s25ql.const";
import { RoleService } from "../../services/role.service";
import { AdvancedStep } from "./s25ql.search.advanced.criteria.component";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { S25Const } from "../../util/s25-const";
import NumericalString = Proto.NumericalString;
import StepType = SearchCriteria.StepType;
import StepParam = SearchCriteria.StepParam;
import Ids = Item.Ids;
import { QLUtil } from "./s25ql.util";

@TypeManagerDecorator("s25-ng-ql-search-advanced-criterion")
@Component({
    selector: "s25-ng-ql-search-advanced-criterion",
    template: `
        <s25-simple-collapse
            *ngIf="isInit"
            [headerText]="name"
            [hasClose]="true"
            (closed)="onClose()"
            [class.hasValidationError]="validationError"
        >
            <div [ngSwitch]="step.step_type_id" class="criterion">
                <ng-container *ngIf="step.step_type_id % 100 === 45">
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--keyword" class="ngBold">Keyword</label>
                        <input
                            id="advancedCriterion{{ id }}--keyword"
                            placeholder="Keyword"
                            class="c-input"
                            [maxLength]="48"
                            [(ngModel)]="stepParam.itemName"
                        />
                    </div>
                </ng-container>

                <ng-container *ngIf="step.step_type_id % 100 >= 50 && step.step_type_id % 100 < 60">
                    <s25-ng-ql-search-advanced-custom-attribute
                        [type]="type"
                        [(modelValue)]="stepParam"
                        (modelValueChange)="onCustomAttributeChange()"
                    ></s25-ng-ql-search-advanced-custom-attribute>
                </ng-container>

                <ng-container *ngIf="step.step_type_id === 20">
                    <div *ngIf="type === 1 && stepParam.related_event_type_id === 11">
                        <s25-ng-checkbox [modelValue]="true" [disabled]="true">Has Related Events</s25-ng-checkbox>
                    </div>
                    <div *ngIf="type === 1 && stepParam.related_event_type_id === 12">
                        <s25-ng-checkbox [modelValue]="true" [disabled]="true">Has Bound Events</s25-ng-checkbox>
                    </div>
                    <div *ngIf="type === 4">
                        <ng-container
                            *ngTemplateOutlet="searchCriteria; context: { type: 'locationRelatedTypes' }"
                        ></ng-container>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="Step.Event.Favorites">
                    <s25-ng-checkbox [modelValue]="true" [disabled]="true">Favorites</s25-ng-checkbox>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Details">
                    <div *ngIf="showAll || stepParam.itemName !== undefined">
                        <label for="advancedCriterion{{ id }}--name" class="ngBold">Event Name</label>
                        <ng-container [ngTemplateOutlet]="searchMethod"></ng-container>
                        <input
                            id="advancedCriterion{{ id }}--name"
                            placeholder="Name"
                            class="c-input"
                            [maxLength]="40"
                            [(ngModel)]="stepParam.itemName"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.event_title !== undefined">
                        <label for="advancedCriterion{{ id }}--title" class="ngBold">Event Title</label>
                        <input
                            id="advancedCriterion{{ id }}--title"
                            placeholder="Title"
                            class="c-input"
                            [maxLength]="248"
                            [(ngModel)]="stepParam.event_title"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.reference_number !== undefined">
                        <label for="advancedCriterion{{ id }}--reference" class="ngBold">Reference</label>
                        <input
                            id="advancedCriterion{{ id }}--reference"
                            placeholder="YYYY-ABCDEF"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.reference_number"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.event_structure_element_type !== undefined">
                        <label for="advancedCriterion{{ id }}--structure" class="ngBold">Structure Element</label>
                        <select
                            id="advancedCriterion{{ id }}--structure"
                            class="cn-form__control"
                            [(ngModel)]="stepParam.event_structure_element_type"
                        >
                            <option value="E">Event</option>
                            <option value="F">Folder</option>
                            <option value="C">Cabinet</option>
                        </select>
                        <span class="note">Note: Cabinets and Folders are not returned in 25Live</span>
                    </div>
                    <div *ngIf="showAll || stepParam.start_dt !== undefined">
                        <label for="advancedCriterion{{ id }}--startDate" class="ngBold">Earliest Start Date</label>
                        <s25-ng-editable-date
                            id="advancedCriterion{{ id }}--startDate"
                            [allowEmpty]="true"
                            [alwaysEditing]="true"
                            [val]="stepParam.start_dt"
                            (valChange)="stepParam.start_dt = $event && toStartOfDay($event)"
                        ></s25-ng-editable-date>
                    </div>
                    <div *ngIf="showAll || stepParam.end_dt !== undefined">
                        <label for="advancedCriterion{{ id }}--endDate" class="ngBold">Latest End Date</label>
                        <s25-ng-editable-date
                            id="advancedCriterion{{ id }}--endDate"
                            [allowEmpty]="true"
                            [alwaysEditing]="true"
                            [val]="stepParam.end_dt"
                            (valChange)="stepParam.end_dt = $event && toEndOfDay($event)"
                        ></s25-ng-editable-date>
                    </div>
                    <div *ngIf="showAll || stepParam.modified_since !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedSince"></ng-container>
                    </div>
                    <div *ngIf="showAll || stepParam.last_modified_by !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedBy"></ng-container>
                    </div>
                    <div *ngIf="showAll || stepParam.alien_uid !== undefined">
                        <label for="advancedCriterion{{ id }}--uid" class="ngBold">Alien UID</label>
                        <input
                            id="advancedCriterion{{ id }}--uid"
                            placeholder="UID"
                            class="c-input"
                            [maxLength]="100"
                            [(ngModel)]="stepParam.alien_uid"
                        />
                    </div>
                    <button *ngIf="!showAll" class="aw-button aw-button--outline" (click)="showAll = true">
                        Show All
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Events">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'events' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.EventSearch">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'event' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.EventSearchExclude">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'event' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Folders">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'eventFolders' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Cabinets">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'eventCabinets' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.CreatedDate">
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--time" class="ngBold">Time</label>
                        <s25-timepicker
                            id="advancedCriterion{{ id }}--time"
                            [(modelValue)]="stepParam.start_time"
                        ></s25-timepicker>
                    </div>
                    <ng-container [ngTemplateOutlet]="fromDate"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Types">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'eventTypes' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.States">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'eventStates' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Organizations">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'organizations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.SponsoringOrganizations">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'organization' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Contacts">
                    <select class="cn-form__control" [(ngModel)]="step.contact_role_id">
                        <option *ngFor="let role of contactRoles" [value]="role.role_id">{{ role.role_name }}</option>
                    </select>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'contacts' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Categories">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'eventCategories' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Requirements">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'eventRequirements' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.Occurrences">
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--startTime" class="ngBold">Start Time:</label>
                        <s25-timepicker
                            id="advancedCriterion{{ id }}--startTime"
                            [(modelValue)]="stepParam.occurrence_start_dt"
                        ></s25-timepicker>
                    </div>

                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--endTime" class="ngBold">End Time:</label>
                        <s25-timepicker
                            id="advancedCriterion{{ id }}--endTime"
                            [(modelValue)]="stepParam.occurrence_end_dt"
                        ></s25-timepicker>
                    </div>

                    <ng-container [ngTemplateOutlet]="fromDate"></ng-container>
                    <ng-container [ngTemplateOutlet]="untilDate"></ng-container>

                    <div>
                        <label for="advancedCriterion{{ id }}--include" class="ngBold">Include</label>
                        <div id="advancedCriterion{{ id }}--include" class="flexRow">
                            <s25-ng-checkbox [(modelValue)]="stepParam.monday" class="">Monday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.tuesday" class="">Tuesday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.wednesday" class="">Wednesday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.thursday" class="">Thursday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.friday" class="">Friday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.saturday" class="">Saturday</s25-ng-checkbox>
                            <s25-ng-checkbox [(modelValue)]="stepParam.sunday" class="">Sunday</s25-ng-checkbox>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.ReservationDetails">
                    <div *ngIf="stepParam.reservation_name !== undefined">
                        <div class="flexRow">
                            <label for="advancedCriterion{{ id }}--segment" class="ngBold">Segment Name</label>
                            <input
                                id="advancedCriterion{{ id }}--segment"
                                type="text"
                                [(ngModel)]="stepParam.reservation_name"
                                class="c-input"
                            />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            stepParam.expected_head_count_gte !== undefined ||
                            stepParam.expected_head_count_lte !== undefined
                        "
                    >
                        <label class="ngBold">Expected Head Count</label>
                        <div class="flexRow">
                            <label for="advancedCriterion{{ id }}--expectedMin">Minimum:</label>
                            <input
                                id="advancedCriterion{{ id }}--expectedMin"
                                type="number"
                                [(ngModel)]="stepParam.expected_head_count_gte"
                                class="c-input"
                            />
                        </div>
                        <div class="flexRow">
                            <label for="advancedCriterion{{ id }}--expectedMax">Maximum:</label>
                            <input
                                id="advancedCriterion{{ id }}--expectedMax"
                                type="number"
                                [(ngModel)]="stepParam.expected_head_count_lte"
                                class="c-input"
                            />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            stepParam.registered_head_count_gte !== undefined ||
                            stepParam.registered_head_count_lte !== undefined
                        "
                    >
                        <label class="ngBold">Registered Head Count</label>
                        <div class="flexRow">
                            <label for="advancedCriterion{{ id }}--registeredMin">Minimum:</label>
                            <input
                                id="advancedCriterion{{ id }}--registeredMin"
                                type="number"
                                [(ngModel)]="stepParam.registered_head_count_gte"
                                class="c-input"
                            />
                        </div>
                        <div class="flexRow">
                            <label for="advancedCriterion{{ id }}--registeredMax">Maximum:</label>
                            <input
                                id="advancedCriterion{{ id }}--registeredMax"
                                type="number"
                                [(ngModel)]="stepParam.registered_head_count_lte"
                                class="c-input"
                            />
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.AssignedSpaces">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { none: false }"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'locations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.SpaceAssignments">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'location' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.SpacePreferences">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { none: false }"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'locations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.MissingSpaceAssignments">
                    <ng-container [ngTemplateOutlet]="fromDate"></ng-container>
                    <ng-container [ngTemplateOutlet]="untilDate"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.AssignedResources">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { none: false }"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'resources' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.ResourcesAssignments">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'resource' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.OutstandingNeedsSpaceVCalendarToDos">
                    <s25-ng-checkbox [modelValue]="true" [disabled]="true"
                        >Outstanding vCalendar "To Do": Needs Space</s25-ng-checkbox
                    >
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.OutstandingExceedsHeadcountVCalendarToDos">
                    <s25-ng-checkbox [modelValue]="true" [disabled]="true"
                        >Outstanding vCalendar "To Do" Tasks: Exceeds Head Count</s25-ng-checkbox
                    >
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.OutstandingExportVCalendarToDos">
                    <s25-ng-checkbox [modelValue]="true" [disabled]="true"
                        >Outstanding vCalendar "To Do" Tasks: Export</s25-ng-checkbox
                    >
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.ProfileCode">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <s25-ng-ql-search-advanced-profile
                        [(profileCodes)]="profileCodes"
                        (profileCodesChange)="onProfileCodesChange($event)"
                    >
                    </s25-ng-ql-search-advanced-profile>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Event.StandardSchedule">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'standardSchedules' }"
                    ></ng-container>
                    <s25-simple-collapse
                        [headerText]="'Meeting Patterns (' + meetingPatternsCount + ')'"
                        [defaultCollapsed]="true"
                    >
                        <s25-ng-standard-schedule-patterns
                            [options]="{ showScheduleName: true }"
                            [items]="step.step_param"
                            (patternsCount)="meetingPatternsCount = $event"
                        ></s25-ng-standard-schedule-patterns>
                    </s25-simple-collapse>
                </ng-container>

                <ng-container *ngSwitchCase="Step.Location.Details">
                    <div *ngIf="showAll || stepParam.itemName !== undefined">
                        <label for="advancedCriterion{{ id }}--name" class="ngBold">Location Name</label>
                        <ng-container [ngTemplateOutlet]="searchMethod"></ng-container>
                        <input
                            id="advancedCriterion{{ id }}--name"
                            placeholder="Name"
                            class="c-input"
                            [maxLength]="40"
                            [(ngModel)]="stepParam.itemName"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.formal_name !== undefined">
                        <label for="advancedCriterion{{ id }}--formal" class="ngBold">Formal Name</label>
                        <input
                            id="advancedCriterion{{ id }}--formal"
                            placeholder="Formal Name"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.formal_name"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.max_capacity !== undefined">
                        <label for="advancedCriterion{{ id }}--maxCapacity" class="ngBold">Maximum Capacity</label>
                        <input
                            id="advancedCriterion{{ id }}--maxCapacity"
                            type="number"
                            class="c-input"
                            [(ngModel)]="stepParam.max_capacity"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.min_capacity !== undefined">
                        <label for="advancedCriterion{{ id }}--minCapacity" class="ngBold">Minimum Capacity</label>
                        <input
                            id="advancedCriterion{{ id }}--minCapacity"
                            type="number"
                            class="c-input"
                            [(ngModel)]="stepParam.min_capacity"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.fill_ratio !== undefined">
                        <label for="advancedCriterion{{ id }}--fillRatio" class="ngBold">Fill Ratio</label>
                        <input
                            id="advancedCriterion{{ id }}--fillRatio"
                            type="number"
                            class="c-input"
                            [(ngModel)]="stepParam.fill_ratio"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.modified_since !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedSince"></ng-container>
                    </div>
                    <div *ngIf="showAll || stepParam.last_modified_by !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedBy"></ng-container>
                    </div>
                    <button *ngIf="!showAll" class="aw-button aw-button--outline" (click)="showAll = true">
                        Show All
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Spaces">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'locations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Search">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'location' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Buildings">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'buildings' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Partitions">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'locationPartitions' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Features">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'locationFeatures' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Categories">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'locationCategories' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.Layouts">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'locationLayouts' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.SpaceRelationships">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <div>
                        <label for="advancedCriterion{{ id }}--relationship" class="ngBold">Relationship</label>
                        <select
                            id="advancedCriterion{{ id }}--relationship"
                            [(ngModel)]="step.relationship_type_id"
                            (ngModelChange)="updateRelationshipType($event)"
                            class="cn-form__control"
                        >
                            <option value="1">Subdivision Of</option>
                            <option value="2">Close To</option>
                            <option value="3">Blocked By</option>
                            <option value="4">Also Assign</option>
                            <option value="5">Divides Into</option>
                        </select>
                    </div>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'locations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.AssignedSpaces">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'events' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Location.SpaceAssignments">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'event' }"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="Step.Organization.Details">
                    <div *ngIf="showAll || stepParam.itemName !== undefined">
                        <label for="advancedCriterion{{ id }}--name" class="ngBold">Organization Name</label>
                        <ng-container [ngTemplateOutlet]="searchMethod"></ng-container>
                        <input
                            id="advancedCriterion{{ id }}--name"
                            placeholder="Name"
                            class="c-input"
                            [maxLength]="40"
                            [(ngModel)]="stepParam.itemName"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.formal_name !== undefined">
                        <label for="advancedCriterion{{ id }}--title" class="ngBold">Organization Title</label>
                        <input
                            id="advancedCriterion{{ id }}--title"
                            placeholder="Formal Name"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.formal_name"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.s25_keys !== undefined">
                        <s25-ng-checkbox [(modelValue)]="stepParam.s25_keys">Schedule25 Key</s25-ng-checkbox>
                    </div>
                    <div *ngIf="showAll || stepParam.accounting_code !== undefined">
                        <label for="advancedCriterion{{ id }}--accountCode" class="ngBold">Accounting Code</label>
                        <input
                            id="advancedCriterion{{ id }}--accountCode"
                            type="text"
                            class="c-input"
                            [maxLength]="100"
                            [(ngModel)]="stepParam.accounting_code"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.modified_since !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedSince"></ng-container>
                    </div>
                    <div *ngIf="showAll || stepParam.last_modified_by !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedBy"></ng-container>
                    </div>
                    <button *ngIf="!showAll" class="aw-button aw-button--outline" (click)="showAll = true">
                        Show All
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Organizations">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'organizations' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Search">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'organization' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Address">
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--type">Address Type</label>
                        <select
                            id="advancedCriterion{{ id }}--type"
                            class="cn-form__control"
                            [(ngModel)]="stepParam.address_type_id"
                        >
                            <option value="-1">(none)</option>
                            <option value="1">Administration</option>
                            <option value="2">Billing</option>
                        </select>
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--contains">Address Contains</label>
                        <input
                            id="advancedCriterion{{ id }}--contains"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.address"
                        />
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--city">City</label>
                        <input
                            id="advancedCriterion{{ id }}--city"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.city"
                        />
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--zip">Zip/Post</label>
                        <input
                            id="advancedCriterion{{ id }}--zip"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.zip"
                        />
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--country">Country</label>
                        <input
                            id="advancedCriterion{{ id }}--country"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.country"
                        />
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--phone">Phone</label>
                        <input
                            id="advancedCriterion{{ id }}--phone"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.phone"
                        />
                    </div>
                    <div class="flexRow">
                        <label for="advancedCriterion{{ id }}--fax">Fax</label>
                        <input
                            id="advancedCriterion{{ id }}--fax"
                            type="text"
                            class="c-input"
                            [maxLength]="78"
                            [(ngModel)]="stepParam.fax"
                        />
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Categories">
                    <ng-container *ngTemplateOutlet="qualifiers"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'organizationCategories' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Contacts">
                    <select class="cn-form__control" [(ngModel)]="step.contact_role_id">
                        <option *ngFor="let role of contactRoles" value="{{ role.role_id }}">
                            {{ role.role_name }}
                        </option>
                    </select>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'contacts' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Types">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'organizationTypes' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.Ratings">
                    <ng-container *ngTemplateOutlet="qualifiers; context: { all: false }"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'organizationRatings' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.AssignedOrganizations">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'events' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Organization.OrganizationAssignments">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'event' }"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="Step.Resource.Details">
                    <div *ngIf="showAll || stepParam.itemName !== undefined">
                        <label for="advancedCriterion{{ id }}--name" class="ngBold">Resource Name</label>
                        <ng-container [ngTemplateOutlet]="searchMethod"></ng-container>
                        <input
                            id="advancedCriterion{{ id }}--name"
                            placeholder="Name"
                            class="c-input"
                            [maxLength]="40"
                            [(ngModel)]="stepParam.itemName"
                        />
                    </div>
                    <div *ngIf="showAll || stepParam.modified_since !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedSince"></ng-container>
                    </div>
                    <div *ngIf="showAll || stepParam.last_modified_by !== undefined">
                        <ng-container [ngTemplateOutlet]="modifiedBy"></ng-container>
                    </div>
                    <button *ngIf="!showAll" class="aw-button aw-button--outline" (click)="showAll = true">
                        Show All
                    </button>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.Resources">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'resources' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.Search">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'resource' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.Categories">
                    <ng-container *ngTemplateOutlet="qualifiers; context: {}"></ng-container>
                    <ng-container
                        *ngTemplateOutlet="searchCriteria; context: { type: 'resourceCategories' }"
                    ></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.Relationships">
                    <ng-container *ngTemplateOutlet="qualifiers; context: {}"></ng-container>
                    <select
                        class="cn-form__control"
                        [ngModel]="step.relationship_type_id"
                        (ngModelChange)="updateRelationshipType($event)"
                    >
                        <option value="8">Substitute With</option>
                        <option value="9">Also Assign</option>
                    </select>
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'resources' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.AssignedResources">
                    <ng-container *ngTemplateOutlet="searchCriteria; context: { type: 'events' }"></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="Step.Resource.ResourceAssignments">
                    <ng-container *ngTemplateOutlet="search; context: { type: 'event' }"></ng-container>
                </ng-container>
            </div>

            <p *ngIf="validationError" class="validationError">
                {{ validationError }}
            </p>
        </s25-simple-collapse>

        <ng-template #searchMethod>
            <select aria-label="operator" class="cn-form__control" [(ngModel)]="stepParam.searchMethod">
                <option value="is">is</option>
                <option value="starts with">Starts With</option>
                <option value="contains">Contains</option>
                <option value="ends with">Ends With</option>
            </select>
        </ng-template>

        <ng-template #modifiedSince>
            <label for="advancedCriterion{{ id }}--modifiedSince" class="ngBold">Modified Since</label>
            <s25-ng-editable-date-time
                [allowEmpty]="true"
                [alwaysEditing]="true"
                [(val)]="stepParam.modified_since"
                [dateInputId]="'advancedCriterion' + id + '--modifiedSince'"
            ></s25-ng-editable-date-time>
        </ng-template>

        <ng-template #modifiedBy>
            <label for="advancedCriterion{{ id }}--lastModifiedBy" class="ngBold">Last Modified By</label>
            <input
                id="advancedCriterion{{ id }}--lastModifiedBy"
                type="text"
                [maxLength]="40"
                [(ngModel)]="stepParam.last_modified_by"
                class="c-input"
            />
            <span class="note">(Enter contact username)</span>
        </ng-template>

        <ng-template #fromDate>
            <div class="">
                <label for="advancedCriterion{{ id }}--from" class="ngBold">From</label>
                <div id="advancedCriterion{{ id }}--from">
                    <div class="flexRow">
                        <s25-ng-radio
                            [(modelValue)]="stepParam.from_dt_type"
                            [name]="'fromDate' + id"
                            [value]="'number'"
                            [style.display]="'block'"
                            (modelValueChange)="stepParam.from_dt = stepParam.from_dt_num"
                        >
                            Today +/-
                        </s25-ng-radio>
                        <input
                            *ngIf="stepParam.from_dt_type === 'number'"
                            [(ngModel)]="stepParam.from_dt_num"
                            (ngModelChange)="stepParam.from_dt = $event"
                            type="number"
                            class="c-input"
                        />
                    </div>
                    <div class="flexRow">
                        <s25-ng-radio
                            [(modelValue)]="stepParam.from_dt_type"
                            [name]="'fromDate' + id"
                            [value]="'date'"
                            (modelValueChange)="stepParam.from_dt = stepParam.from_dt_date"
                        >
                            Specific Date</s25-ng-radio
                        >
                        <s25-ng-editable-date
                            *ngIf="stepParam.from_dt_type === 'date'"
                            [(val)]="stepParam.from_dt_date"
                            (valChange)="stepParam.from_dt = $event"
                        ></s25-ng-editable-date>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #untilDate>
            <div>
                <label for="advancedCriterion{{ id }}--until" class="ngBold">Until</label>
                <div id="advancedCriterion{{ id }}--until" class="flexRow">
                    <s25-ng-radio
                        [(modelValue)]="stepParam.until_dt_type"
                        (modelValueChange)="stepParam.until_dt = stepParam.until_dt_num"
                        [name]="'untilDate' + id"
                        [value]="'number'"
                        [style.display]="'block'"
                        >Day(s) Following</s25-ng-radio
                    >
                    <input
                        *ngIf="stepParam.until_dt_type === 'number'"
                        [(ngModel)]="stepParam.until_dt_num"
                        (ngModelChange)="stepParam.until_dt = $event"
                        type="number"
                        class="c-input"
                    />
                </div>
                <div class="flexRow">
                    <s25-ng-radio
                        [(modelValue)]="stepParam.until_dt_type"
                        [name]="'untilDate' + id"
                        [value]="'date'"
                        (modelValueChange)="stepParam.until_dt = stepParam.until_dt_date"
                    >
                        Specific Date</s25-ng-radio
                    >
                    <s25-ng-editable-date
                        *ngIf="stepParam.until_dt_type === 'date'"
                        [(val)]="stepParam.until_dt_date"
                        (valChange)="stepParam.until_dt = toEndOfDay($event)"
                    ></s25-ng-editable-date>
                </div>
            </div>
        </ng-template>

        <ng-template #qualifiers let-any="any" let-all="all" let-none="none">
            <select [(ngModel)]="step.qualifier" class="cn-form__control" aria-label="search style">
                <option *ngIf="any !== false" [value]="1">Include Any</option>
                <option *ngIf="all !== false" [value]="2">Include All</option>
                <option *ngIf="none !== false" [value]="-1">Do Not Include</option>
            </select>
        </ng-template>

        <ng-template #searchCriteria let-type="type">
            <s25-ng-multiselect-search-criteria
                [type]="type"
                [modelBean]="{ showResult: true }"
                [selectedItems]="step.step_param"
                [popoverPlacement]="'right'"
                [useSecurity]="true"
                (changed)="setObjectsData($event)"
            ></s25-ng-multiselect-search-criteria>
        </ng-template>

        <ng-template #search let-type="type">
            <div class="flexRow">
                <label for="advancedCriterion{{ id }}--search--{{ type }}">Search</label>
                <div *ngIf="stepParam?.isPrivate" class="privateSearch">
                    Private <s25-ng-info-message>You do not have permission to view this search</s25-ng-info-message>
                </div>
                <s25-ng-search-dropdown
                    *ngIf="!stepParam?.isPrivate"
                    id="advancedCriterion{{ id }}--search--{{ type }}"
                    [type]="type"
                    [allowNonQueryId]="false"
                    [chosen]="stepParam"
                    (chosenChange)="setObjectsData([$event])"
                ></s25-ng-search-dropdown>
            </div>
        </ng-template>
    `,
    styles: `
        .criterion {
            display: flex;
            flex-direction: column;
            gap: 1em;
            padding: 1em;
        }

        label,
        select,
        input,
        s25-contact-dropdown,
        s25-ng-editable-date-time,
        ::ng-deep s25-ng-ql-search-advanced-criterion s25-datepicker input {
            width: 14em;
            display: block;
            margin-bottom: 0.5em;
        }

        .flexRow {
            display: flex;
            gap: 1em;
            flex-wrap: wrap;
        }

        .flexRow > * {
            width: fit-content;
            margin: auto 0;
        }

        .flexRow > label {
            min-width: 6em;
        }

        s25-ng-search-dropdown {
            min-width: 17em;
        }

        s25-ng-radio {
            min-width: 10em;
        }

        s25-ng-multiselect-search-criteria .s25-multiselect-popup-container {
            max-width: 50vw;
        }

        .validationError {
            padding: 1em;
            color: #c00;
            font-size: 0.9em;
        }

        ::ng-deep s25-ng-ql-search-advanced-criterion .hasValidationError .simple-collapse--wrapper {
            border: 1px solid #c00 !important;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criterion .validationError {
            color: #ff8b8b;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criterion .hasValidationError .simple-collapse--wrapper {
            border: 1px solid #ff8b8b !important;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criterion .privateSearch {
            color: #fff;
        }

        .note {
            color: #707070;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criterion .note {
            color: #aaa;
        }

        ::ng-deep s25-ng-standard-schedule-patterns s25-ng-table {
            margin-top: 1em;
            margin-left: 0.625rem;
        }
    `,
})
export class S25qlSearchAdvancedCriterionComponent implements OnInit {
    public static count = 0;

    @Input() type: Item.Id;
    @Input() step: AdvancedStep;

    @Output() closed = new EventEmitter<void>();

    id: number;
    isInit = false;
    Step = StepType;
    S25QLConst = S25QLConst;
    contactRoles: { role_id: string; role_name: string }[] = [];
    name: string;
    showAll = false;
    validationError: string;
    profileCodes: string[];
    meetingPatternsCount: number;

    toStartOfDay = S25Util.date.toStartOfDay;
    toEndOfDay = S25Util.date.toEndOfDay;

    get stepParam() {
        return this.step.step_param[0];
    }
    set stepParam(param: StepParam) {
        this.step.step_param[0] = param;
    }

    constructor() {
        this.id = S25qlSearchAdvancedCriterionComponent.count;
        S25qlSearchAdvancedCriterionComponent.count++;
    }

    async ngOnInit() {
        let stepTypeId = S25QLConst.stepNormMap[this.step.step_type_id] || Number(this.step.step_type_id);
        // Sets step_type_id to custom attribute
        this.step.step_type_id = stepTypeId;
        this.name = this.getName(this.step);
        if (stepTypeId === StepType.Event.Contacts || stepTypeId === StepType.Organization.Contacts) {
            this.contactRoles = await RoleService.getRolesFiltered(this.type);
            this.contactRoles.unshift({ role_id: "0", role_name: "Any Role" });
            this.step.contact_role_id ??= "0";
        }

        if (stepTypeId === StepType.Event.Occurrences) {
            for (let day of ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"])
                this.stepParam[day] = this.stepParam[day] === "T";
        }
        if (this.stepParam?.from_dt !== undefined) {
            this.stepParam.from_dt_type =
                isNaN(this.stepParam.from_dt) || S25Util.date.isDate(this.stepParam.from_dt) ? "date" : "number";
            this.stepParam.from_dt_date =
                this.stepParam.from_dt_type === "date" ? this.stepParam.from_dt : S25Util.date.toStartOfDay(new Date());
            this.stepParam.from_dt_num = this.stepParam.from_dt_type === "date" ? 0 : this.stepParam.from_dt;
        }
        if (this.stepParam?.until_dt !== undefined) {
            this.stepParam.until_dt_type =
                isNaN(this.stepParam.until_dt) || S25Util.date.isDate(this.stepParam.until_dt) ? "date" : "number";
            this.stepParam.until_dt_date =
                this.stepParam.until_dt_type === "date" ? this.stepParam.until_dt : S25Util.date.toEndOfDay(new Date());
            this.stepParam.until_dt_num = this.stepParam.until_dt_type === "date" ? 1 : this.stepParam.until_dt;
        }
        if (stepTypeId === StepType.Location.SpaceRelationships || stepTypeId === StepType.Resource.Relationships) {
            this.step.step_param = this.step.step_param.filter((item) => item.itemName);
            if (!this.step.step_param.length)
                this.updateRelationshipType(stepTypeId === StepType.Location.SpaceRelationships ? 1 : 8); // Select first by default
        }
        if (this.stepParam?.relationship_type_id !== undefined) {
            this.updateRelationshipType(Number(this.stepParam.relationship_type_id) || 1);
        }
        if (this.stepParam?.contact_role_id !== undefined)
            this.step.contact_role_id = Number(this.stepParam.contact_role_id) || "0";
        if (this.stepParam?.profile_code !== undefined) {
            this.profileCodes = this.step.step_param.map((p) => p.profile_code || "hh:mm|hh:mm|W1 MO TU WE TH FR");
            this.onProfileCodesChange(this.profileCodes);
        }

        this.step.qualifier ??= 1;

        // Set "show all" when the right number of parameters are present
        const paramValues = Object.keys(this.stepParam ?? {}).length;
        const showAllValues: any = {
            [StepType.Event.Details]: 10,
            [StepType.Location.Details]: 8,
            [StepType.Organization.Details]: 7,
            [StepType.Resource.Details]: 6,
        };
        if (showAllValues[stepTypeId] === paramValues) this.showAll = true;

        this.isInit = true;
    }

    getName(step: AdvancedStep): string {
        let stepType = Number(step.step_type_id);
        if (stepType % 100 >= 50 && stepType % 100 < 60) stepType = this.type * 100 + 50;
        const stepParam = step.step_param[0];

        if (this.type === Item.Ids.Event && stepType === StepType.Event.IncludeRelatedEvents) {
            if (stepParam.related_event_type_id === 11) return "Has Related Events";
            if (stepParam.related_event_type_id === 12) return "Has Bound Events";
        }

        return S25QLConst.advancedUIOptionName[this.type][stepType];
    }

    onClose() {
        this.closed.emit();
    }

    setObjectsData(data: any[]) {
        const relationship_type_id = this.step.relationship_type_id || 1;
        const relationshipIsItemId = this.type === Ids.Location && this.step.step_type_id === 20;
        this.step.step_param = data.map(({ itemName, itemId, txt, val }) => ({
            itemName,
            itemId: String(itemId),
            relationship_type_id: relationshipIsItemId ? itemId : relationship_type_id,
            txt,
            val,
        }));
    }

    updateRelationshipType(type: number | NumericalString) {
        this.step.relationship_type_id = type;
        for (let param of this.step.step_param) param.relationship_type_id = type;
    }

    onCustomAttributeChange() {
        if (!this.stepParam.relationship_type_id) return;
        this.step.step_type_id = this.stepParam.relationship_type_id % 1000;
    }

    onProfileCodesChange(params: string[]) {
        this.step.step_param = params.map((p) => {
            return { profile_code: p || "" };
        });
    }
    validate(): boolean {
        let id = Number(this.step.step_type_id);
        if (id % 100 >= 50 && id % 100 < 60) id = this.type * 100 + 50; // If custom attribute, set stepTypeId to X50
        const itemTypeData = AdvancedSearchUtil.s25SearchAdvancedStepTemplate[S25Const.itemId2Name[this.type]];
        const validator = itemTypeData.validation?.[id];
        this.validationError = validator?.(this.step) || null;
        return !this.validationError; // No error message = OK
    }
}
