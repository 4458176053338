import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Item } from "../../pojo/Item";
import { SearchCriteriaService } from "../../services/search/search-criteria/search.criteria.service";
import { AttributeI } from "../../pojo/AttributeI";
import { DropDownItem } from "../../pojo/DropDownItem";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { S25Util } from "../../util/s25-util";

@TypeManagerDecorator("s25-ng-ql-search-advanced-custom-attribute")
@Component({
    selector: "s25-ng-ql-search-advanced-custom-attribute",
    template: `
        <div *ngIf="isInit">
            <label class="ngBold">Attribute</label>
            <s25-generic-dropdown
                [items]="items"
                [focusFirst]="false"
                [searchEnabled]="true"
                [placeholder]="'Select a Custom Attribute'"
                [chosen]="{ itemId: modelValue.cust_atrb_id }"
                (chosenChange)="onAttributeSet($event)"
                class="c-margin-bottom--half ngBlock limitWidth"
            ></s25-generic-dropdown>

            <label *ngIf="modelValue.cust_atrb_id" class="ngBold">Operator</label>
            <s25-search-operators-dropdown
                *ngIf="modelValue.cust_atrb_id"
                [attributeTypeId]="modelValue.cust_atrb_type"
                [(chosen)]="operator"
                (chosenChange)="onOperatorSet($event)"
                class="c-margin-bottom--half ngBlock limitWidth"
            ></s25-search-operators-dropdown>

            <div
                *ngIf="
                    modelValue.relationship_type_id &&
                    (modelValue.relationship_type_id % 100 <= 55 || modelValue.relationship_type_id % 100 === 58) &&
                    modelValue.relationship_type_id < 1000
                "
            >
                <label class="ngBold">Value</label>
                <div *ngIf="modelValue.relationship_type_id && searchCriteria">
                    <s25-ng-multiselect-search-criteria
                        [type]="searchCriteria"
                        [modelBean]="{ showResult: true, singleSelect: true }"
                        [selectedItems]="selectedSearchCriteria"
                        (changed)="modelValue.itemId = $event[0].itemId; modelValue.itemName = $event[0].itemName"
                    ></s25-ng-multiselect-search-criteria>
                </div>
                <div *ngIf="!searchCriteria">
                    <ng-container [ngSwitch]="modelValue.cust_atrb_type">
                        <div *ngSwitchCase="'D'">
                            <s25-ng-editable-date [(val)]="modelValue.itemId"></s25-ng-editable-date>
                        </div>
                        <div *ngSwitchCase="'T'">
                            <s25-timepicker [(modelValue)]="modelValue.itemId"></s25-timepicker>
                        </div>
                        <div *ngSwitchCase="'E'">
                            <s25-ng-editable-date-time [(val)]="modelValue.itemId"></s25-ng-editable-date-time>
                        </div>
                        <div *ngSwitchCase="'N'">
                            <input type="number" [(ngModel)]="modelValue.itemId" />
                        </div>
                        <div *ngSwitchCase="'F'">
                            <input type="number" class="c-input" [(ngModel)]="modelValue.itemId" />
                        </div>
                        <div *ngSwitchCase="'R'">
                            <input type="text" class="c-input" [(ngModel)]="modelValue.itemId" />
                        </div>
                        <div *ngSwitchCase="'S'">
                            <input type="text" class="c-input" [maxLength]="80" [(ngModel)]="modelValue.itemId" />
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    `,
})
export class S25qlSearchAdvancedCustomAttributeComponent implements OnInit {
    @Input() type: Item.Id;
    @Input() modelValue: CustomAttribute;

    @Output() modelValueChange = new EventEmitter<CustomAttribute>();

    isInit = false;
    items: DropDownItem[];
    searchCriteria: SearchCriteriaType["type"];
    selectedSearchCriteria: DropDownItem[];
    operator: any;

    async ngOnInit() {
        const data: AttributeI[] = (await SearchCriteriaService.getCustomAttributeItems(this.type)) || [];
        this.items = data.map((obj) => {
            return {
                itemName: obj.itemName,
                itemId: obj.itemId,
                type: obj.itemTypeId,
            };
        });
        if (!this.modelValue) {
            this.modelValue = {
                cust_atrb_id: null,
                cust_atrb_name: null,
                cust_atrb_type: null,
                itemId: null,
                relationship_type_id: null,
                relationship_type_name: null,
            };
        } else {
            if (!!Number(this.modelValue.cust_atrb_type))
                this.modelValue.cust_atrb_type = Number(this.modelValue.cust_atrb_type);
            this.setSearchCriteria();
            this.selectedSearchCriteria = [{ itemId: this.modelValue.itemId, itemName: this.modelValue.itemName }];
        }

        if (this.modelValue.relationship_type_id % 100 === 50 && this.modelValue.cust_atrb_type === "B")
            this.modelValue.relationship_type_id = this.modelValue.itemId === "T" ? 1050 : 2050;

        if (typeof this.modelValue.itemId === "string")
            this.modelValue.itemId = this.modelValue.itemId.replace(/^%(.*?)%$/, "$1");

        const relationship = this.modelValue.relationship_type_id;
        this.operator = {
            itemId: relationship > 1000 ? relationship : relationship % 100,
        };

        this.isInit = true;
    }

    onAttributeSet(data: DropDownItem) {
        this.modelValue.cust_atrb_id = data.val as number;
        this.modelValue.cust_atrb_name = data.itemName;
        this.modelValue.cust_atrb_type = data.type;
        this.modelValue.relationship_type_id = null;
        this.setSearchCriteria();
        this.modelValueChange.emit(this.modelValue);
    }

    setSearchCriteria() {
        if (!isNaN(this.modelValue.cust_atrb_type as number)) {
            const types: string[] = ["", "", "organizations", "contacts", "locations", "", "resources"];
            this.searchCriteria = types[this.modelValue.cust_atrb_type as number] as SearchCriteriaType["type"];
        } else this.searchCriteria = null;
    }

    onOperatorSet(data: DropDownItem) {
        if (S25Util.parseInt(data.val) > 1000) {
            this.modelValue.itemId = data.val === 1050 ? "T" : "F";
        }

        this.modelValue.relationship_type_id = (data.val as number) + this.type * 100;
        this.modelValue.relationship_type_name = data.itemName;
        this.modelValueChange.emit(this.modelValue);
    }
}

type CustomAttribute = {
    cust_atrb_id: number;
    cust_atrb_name?: string;
    cust_atrb_type: string | number;
    itemId: string | number;
    itemName?: string;
    relationship_type_id: number;
    relationship_type_name: string;
};
