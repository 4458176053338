import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from "@angular/core";
import { S25QLConst } from "./s25ql.const";
import { Item } from "../../pojo/Item";
import { DropDownItem } from "../../pojo/DropDownItem";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { AdvancedSearchUtil } from "../advanced-search/advanced-search-util";
import { Tokenizer } from "./s25ql.tokenizer";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Proto } from "../../pojo/Proto";
import { S25qlSearchAdvancedCriterionComponent } from "./s25ql.search.advanced.criterion.component";
import { S25Const } from "../../util/s25-const";
import { QLUtil } from "./s25ql.util";
import Model = SearchCriteria.Model;
import Searches = SearchCriteria.Searches;
import Step = SearchCriteria.Step;
import StepType = SearchCriteria.StepType;
import NumericalString = Proto.NumericalString;

@TypeManagerDecorator("s25-ng-ql-search-advanced-criteria")
@Component({
    selector: "s25-ng-ql-search-advanced-criteria",
    template: `
        <s25-simple-collapse
            *ngIf="isInit"
            [headerText]="'Query'"
            [hasClose]="hasClose"
            (closed)="onClose()"
            [class.hasValidationError]="validationError"
        >
            <div class="criteriaWrapper">
                <div class="criteriaRow">
                    <label for="conjunction">Conjunction</label>
                    <select id="conjunction" [(ngModel)]="model.query_method" class="cn-form__control">
                        <option value="all">and</option>
                        <option value="any">or</option>
                    </select>
                </div>

                <ng-container *ngFor="let step of model.step; let i = index">
                    <s25-ng-ql-search-advanced-criteria
                        #advancedCriteria
                        *ngIf="isTempSearch(step)"
                        [type]="type"
                        [hasClose]="true"
                        (closed)="removeStep(i)"
                        [model]="searches[step.step_param[0].itemName]"
                        [searches]="searches"
                    ></s25-ng-ql-search-advanced-criteria>
                    <s25-ng-ql-search-advanced-criterion
                        #advancedCriterion
                        *ngIf="!isTempSearch(step)"
                        [type]="type"
                        [step]="step"
                        (closed)="removeStep(i)"
                    ></s25-ng-ql-search-advanced-criterion>
                </ng-container>

                <s25-dropdown-paginated
                    class="addCriteriaButton"
                    [model]="dropdownModel"
                    [chosen]="chosen"
                    [choice]="choice"
                    [arrayChoice]="choice"
                    (chosenChange)="addStep($event)"
                ></s25-dropdown-paginated>

                <p *ngIf="validationError" class="validationError">
                    {{ validationError }}
                </p>
            </div>
        </s25-simple-collapse>

        <ng-template #choice let-item="item">
            <div class="{{ item.iconClass }}">{{ item.itemName }}</div>
        </ng-template>
    `,
    styles: `
        .criteriaWrapper {
            padding: 0.5em 0 0.5em 1em;
        }

        .criteriaRow {
            display: flex;
            gap: 0.5em;
        }

        .criteriaRow > label {
            margin: auto 0;
        }

        .addCriteriaButton {
            width: 9em;
            display: block;
            margin-top: 1em;
        }

        :host ::ng-deep .addCriteriaButton .select2-choice::after {
            display: none;
        }

        :host ::ng-deep .addCriteriaButton .select2-drop {
            width: 24em !important;
            left: 0;
        }

        :host ::ng-deep .addCriteriaButton .select2-results {
            max-height: Min(60vh, 800px);
        }

        :host s25-simple-collapse ::ng-deep s25-simple-collapse .simple-collapse--wrapper {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .validationError {
            padding-top: 1em;
            color: #c00;
            font-size: 0.9em;
        }

        ::ng-deep s25-ng-ql-search-advanced-criteria .hasValidationError .simple-collapse--wrapper {
            border: 1px solid #c00 !important;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criteria .validationError {
            color: #ff8b8b;
        }

        ::ng-deep .nm-party--on s25-ng-ql-search-advanced-criteria .hasValidationError .simple-collapse--wrapper {
            border: 1px solid #ff8b8b !important;
        }
    `,
})
export class S25qlSearchAdvancedCriteriaComponent implements OnChanges, OnInit {
    @Input() type: Tokenizer.QLItemTypes;
    @Input() hasClose: boolean = false;
    @Input() model: AdvancedModel;
    @Input() searches: Searches;

    @Output() closed = new EventEmitter<void>();

    @ViewChildren("advancedCriteria") advancedCriteriaComponents: QueryList<S25qlSearchAdvancedCriteriaComponent>;
    @ViewChildren("advancedCriterion") advancedCriterionComponents: QueryList<S25qlSearchAdvancedCriterionComponent>;

    chosen: {} = null;
    conjunction: "and" | "or" = "and";
    criteria: Criterion[] = [];
    dropdownModel: { items: any[]; placeholder: string };
    isInit = false;
    validationError: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.type && !changes.type.isFirstChange()) this.updateDropdownModel();
    }

    async ngOnInit() {
        this.updateDropdownModel();
        this.isInit = true;
    }

    updateDropdownModel() {
        let items = S25QLConst.advancedUIOptions[this.type];
        this.dropdownModel = { items, placeholder: "Add Criterion" };
    }

    addStep(data: DropDownItem) {
        this.clearValidationError();
        this.chosen = {}; // Reset Chosen
        const { itemId, itemName, stepTypeId, subStepTypeId, iconClass } = data;
        if (stepTypeId < 0) {
            const tempSearchKey = `temp_${S25Util.generateQuickGUID()}`;
            const step_type_id = this.type === Item.Ids.Event ? StepType.Event.EventSearch : this.type * 100 + 5; // All others are X05
            const step: Step = {
                ...AdvancedSearchUtil.s25SearchAdvancedStepTemplate[S25Const.itemId2Name[this.type]][step_type_id],
                step_param: [{ itemName: tempSearchKey, itemId: tempSearchKey }],
            };
            this.searches[tempSearchKey] = { query_method: "all", step: [] };
            this.model.step.push(step);
        } else {
            const step: AdvancedStep = AdvancedSearchUtil.getStep(this.type, stepTypeId, subStepTypeId);
            step.sub_step_type_id = subStepTypeId;
            step.name = itemName;
            this.model.step.push(step);
        }

        this.changeDetector.detectChanges();
    }

    removeStep(index: number) {
        this.clearValidationError();
        this.model.step.splice(index, 1);
    }

    onClose() {
        this.closed.emit();
    }

    isTempSearch(step: Step): boolean {
        return QLUtil.isStepTemp(step);
    }

    clearValidationError() {
        this.validationError = null;
    }

    validate(): boolean {
        let ok = true;
        if (!this.model.step.length) {
            ok = false;
            this.validationError = "Please add at least one criterion.";
        }
        for (let criteria of this.advancedCriteriaComponents.toArray()) {
            ok = criteria.validate() && ok;
        }
        for (let criterion of this.advancedCriterionComponents.toArray()) {
            ok = criterion.validate() && ok;
        }
        return ok;
    }
}

export type AdvancedModel = Model & { step: AdvancedStep[] };

export type AdvancedStep = Step & {
    sub_step_type_id: number | NumericalString;
    name: string;
    relationship_type_id?: number | NumericalString;
};

export type Criterion = {
    itemId: string | number;
    itemName: string;
    stepTypeId: number;
    subStepTypeId?: number;
    iconClass?: string;
};
